$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.HugeChoiceInput {
  display: flex;
  --HugeChoiceInputMobileButtonWidth: 30%;
  &-option {
    display: flex;
    flex: 1 0 1%;
    &-description {
      flex: 1 1 60%;
      padding: 10px 0;
      @include removeVerticalMarginFromChildren; }
    &-disabled &-description {
      opacity: 0.3; } }

  @include onScreensNarrowerThan('phablet') {
    flex-direction: column;
    &-option {
      flex-direction: row;
      align-items: center;
      &-button {
        flex: 0 0 var(--HugeChoiceInputMobileButtonWidth);
        padding: 10px 0; }
      &:not(:last-child) {
        border-bottom: 1px solid var(--border-grey);
        margin-bottom: -1px; }
      &-description {
        padding: 10px; } } }

  @include onScreensWiderThan('phablet') {
    flex-direction: row;
    &-option {
      flex-direction: column;
      align-items: stretch;
      &:not(:last-child) {
        padding-right: 5px;
        margin-right: 5px;
        border-right: 1px solid var(--border-grey); }
      &-description {
        text-align: center; } } }

  &-noDescriptions > &-option {
    border-right: 0; } }
