$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.NotificationsList {
  &-Notification {
    position: relative;
    display: flex;
    padding: 10px;
    border-bottom: 1px solid var(--border-grey-lightened);
    &:hover, &:focus, &:focus-within {
      background-color: var(--background-grey); }
    &-unread {
      &:after {
        content: " ";
        background-color: red;
        height: 10px;
        width: 10px;
        border-radius: 50%;
        position: absolute;
        top: calc(50% - 5px);
        left: 5px; } }
    > *:first-child {
      flex: 0 0 33px;
      margin-right: var(--horizontal-padding);
      margin-left: var(--horizontal-padding);
      align-self: center; }
    > *:last-child {
      flex: 1 1 auto; }
    .TimeAgo {
      color: var(--grey-text); } } }

