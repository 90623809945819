$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.FeedPostAndCommentForm {
  &-Controls {
    position: relative;
    z-index: 1;
    > * {
      margin-top: var(--vertical-space); }
    flex-wrap: wrap;
    > span {
      flex-grow: 1;
      height: 35px; } }
  .TextArea > textarea[disabled], .Dropdown-disabled {
    background-color: transparent; } }
