$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationInfo {
  position: relative;
  &-desc {
    max-width: 40em;
    text-align: justify;
    white-space: pre-line; }
  &-domain {
    display: block;
    margin-top: var(--vertical-space);
    &-minimal {
      margin-top: 0; } }
  &-deets {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    > * {
      flex: 0 0 200px;
      margin-top: var(--vertical-space);
      margin-right: var(--horizontal-space); } }
  &-minimal &-deets {
    flex-direction: column;
    align-items: center;
    > * {
      flex-basis: 0; } } }
