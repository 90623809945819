$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.ContextMenu {
  position: relative;

  &-Menu {
    visibility: hidden; // until first position
    position: absolute;
    // z-index: 10
    // position: fixed
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background-color: white;
    padding: 0;
    overflow: hidden;
    @include contentBoxBorder;
    overflow: auto;
    overscroll-behavior: none;
    max-height: unquote('max(400px, 50vh)');
    max-width: unquote('min(400px, calc(100vw - 20px))'); }


  &-Hr {
    border-bottom: 1px solid var(--border-grey);
    padding: 0; } }
