$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.ButtonWithDropdown {
  display: inline-block;
  position: relative;

  &-button {
    padding-right: 45px;
    height: 100%; }

  &:focus {
    outline: none; }

  @each $type in 'normal', 'primary', 'success' {
    &-#{$type} &-dropdownMenu {
      border-color: var(--#{$type}-button-border-color);
      color: var(--#{$type}-button-text-color);
      background-color: var(--#{$type}-button-background-color); }
    &-#{$type} &-iconContainer {
      border: 1px solid var(--#{$type}-button-border-color);
      &:hover {
        background-color: var(--#{$type}-button-background-color-darkened);
        border-color: var(--#{$type}-button-hover-border-color); }
      &-disabled {
        &, &:hover {
          color: var(--#{$type}-button-disabled-text-color);
          background-color:  var(--#{$type}-button-disabled-background-color);
          border-color: var(--#{$type}-button-disabled-border-color); }
        .ButtonWithDropdown-collapseIcon {
          color: var(--#{$type}-button-disabled-text-color); } } }
    &-#{$type} &-option {
      &:hover, &-active {
        background-color: var(--#{$type}-button-hover-background-color); } }
    &-#{$type} &-collapseIcon {
      color: var(--#{$type}-button-text-color); } }

  &-iconContainer {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;
    &-disabled {
      cursor: not-allowed; }
    > .Icon::before {
      width: 28px; } }

  &-collapseIcon {
    // margin-left: 2px
    transition: transform 200ms, top 200ms;
    transform-style: preserve-3d;
    position: relative;
    top: 1px; }

  &-open &-collapseIcon {
    transform: rotateX(180deg);
    top: 0px; }

  &-dropdownMenu {
    z-index: 1;
    position: absolute;
    right: 0;
    top: calc(100% - 3px);
    border-radius: 4px;
    padding: 5px 0px;
    margin-top: 3px;
    border: 1px solid;
    box-shadow: 0 1px 3px 0px rgba(0,0,0,0.5); }

  &-option {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 10px;
    text-align: left;
    cursor: pointer; }

  &-slide {
    &-enter {
      opacity: 0; }
    &-enter-active {
      opacity: 1;
      transition: opacity 200ms ease-out; }
    &-exit {
      opacity: 1; }
    &-exit-active {
      opacity: 0;
      transition: opacity 200ms ease-out; } } }
