$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import 'style/mixins.sass';

.OrganizationMembersList {
  .HeaderedContentBox > .HeaderedContent > .HeaderedContent-content {
    padding: var(--vertical-space) 0;
    .PeopleListMember {
      padding-left: 0;
      padding-right: 0; } }

  &-preview {
    margin: 0 var(--horizontal-padding);
    padding: 0 0 calc(var(--vertical-padding) / 4) 0;
    .EndUserBanner {
      box-shadow: var(--content-box-box-shadow);
      margin: 1px;
      width: auto; }
    > *:last-child {
      position: relative;
      margin-top: calc(var(--vertical-padding) / 2); } }

  &-visit {
    margin-left: var(--horizontal-space); }

  .OrganizationMembershipPills {
    @include onScreensNarrowerThan('phone') {
      display: none; } } }
