$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.APortalSignupForm {
  &-emailOrMobile {
    position: relative; }
  &-toggleOptedForEmail {
    position: absolute;
    top: 0;
    right: 0; }
  &-TermsModal {
    text-align: center;
    max-width: 610px;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      text-align: left; } } }
