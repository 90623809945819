$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.DropdownMenu {
  position: relative;

  // the button
  > *:first-child {
    // prevent the button and its children from being dragged
    &, * {
      @include noSelectOrDrag; } }

  > select {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    outline: none; }

  > .ContextMenu {
    &-Hr {
      // border-bottom: 1px solid var(--border-grey)
 } }      // padding: 0
  &-Option {
    white-space: nowrap;
    padding: 5px 1em;
    &:focus {
      background: var(--side-nav-background-button-hover);
      background-color: var(--input-focus-border-color);
      color: white; } } }

