$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.InterfaceHelp {
  --InterfaceHelp-Icon-size: 12px;
  position: relative;
  display: inline-block;

  &-inline,
  &-inline &-button {
    display: inline-flex; }
  &-inline &-button {
    align-self: center;
    margin-left: 0.5ch; }

  &-visible {
    outline: 1px dotted transparent; }
  &-iconHovered {
    outline-color: var(--tru-blue); }


  &-button {
    position: absolute; }
  &-inline &-button {
    position: static; }

  &-button {
    z-index: 2;
    pointer-events: none;
    --InterfaceHelp-Icon-half-size: calc(var(--InterfaceHelp-Icon-size) / 2);
    --position-px: calc((var(--InterfaceHelp-Icon-size) / 4) * var(--position-offset)); }

  &-button[data-v="t"] {
    top: var(--position-px, 0px); }
  &-button[data-v="t"] &-Icon {
    bottom: var(--InterfaceHelp-Icon-half-size); }
  &-button[data-v="b"] {
    bottom: var(--position-px, 0px); }
  &-button[data-v="b"] &-Icon {
    top: var(--InterfaceHelp-Icon-half-size); }

  &-button[data-h="l"] {
    left: var(--position-px, 0px); }
  &-button[data-h="l"] &-Icon {
    right: var(--InterfaceHelp-Icon-half-size); }
  &-button[data-h="r"] {
    right: var(--position-px, 0px); }
  &-button[data-h="r"] &-Icon {
    left: var(--InterfaceHelp-Icon-half-size); }

  &-Icon {
    position: relative;
    display: flex;
    font-weight: bolder;
    color: var(--tru-blue);
    animation-duration: 250ms;
    animation-timing-function: ease; }

  &-visible &-Icon {
    pointer-events: all;
    animation-name: InterfaceHelp-Icon-enter;
    animation-fill-mode: forwards;
    animation-delay: var(--animation-delay, 0ms); }

  &-Icon {
    opacity: 0;
    animation-name: InterfaceHelp-Icon-exit;
    animation-fill-mode: forwards;

    transform: scale(1.01);
    &:hover > * {
      transform: scale(1.25); }

    &, > * {
      border-radius: 50%; }
    &, > *, > * > * {
      height: var(--InterfaceHelp-Icon-size);
      width: var(--InterfaceHelp-Icon-size); }
    > * {
      pointer-events: none;
      @include noSelectOrDrag;
      background-color: white;
      position: relative;
      > i {
        position: absolute;
        top: -2px;
        left: -2px;
        font-size: 14px;
        &:before {
          text-shadow: 0px 0px 5px white; } } } } }

@keyframes InterfaceHelp-Icon-enter {
  0% {
    opacity: 0;
    transform: scale(0); }
  75% {
    opacity: 1;
    transform: scale(1.26); }
  100% {
    opacity: 1;
    transform: scale(1.01); } }

@keyframes InterfaceHelp-Icon-exit {
  0% {
    opacity: 1;
    transform: scale(1.01); }
  100% {
    opacity: 0;
    transform: scale(0); } }
