$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.PageAlerts {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  align-items: center;
  pointer-events: none;
  > * {
    pointer-events: all;
    margin-top: var(--vertical-space);
    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 90%), inset 0 0 0 0 rgba(0, 0, 0, 99%); }
  &-transition {
    &-enter {
      opacity: 0; }
    &-enter-active {
      opacity: 1;
      transition: opacity 300ms ease-out; }
    &-exit {
      opacity: 1; }
    &-exit-active {
      opacity: 0;
      transition: opacity 300ms ease-out; } } }
