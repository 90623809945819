$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationAdminPage {
  // min-height: 400px
  height: var(--OrganizationPage-maxPageHeight);
  overflow: hidden;
  > .LeftDrawer-content {
    overflow: auto;
    > .PageNotFound {
      justify-content: center;
      flex-grow: 1; } }

  &-PageLink, &-padded {
    @include padded; }

  &-padded > .Header {
    padding: 0; }

  .OrganizationBannerInput .CropImageInput-image {
    width: unquote('max(400px, calc(var(--max-page-column-width) - 225px))') !important; }

  &-PageLink {
    display: flex;
    white-space: nowrap;
    align-items: center;
    &:hover, &:focus, &:active {
      background: var(--background-grey); }
    &-selected {
      &, &:hover, &:focus, &:active {
        background: var(--background-grey-darkened); } }
    .Icon-circle {
      margin-left: auto;
      opacity: 0;
      transition: opacity 200ms ease-in-out;
      color: var(--unsaved-border-color); }
    &-dirty .Icon-circle {
      opacity: 1; } }

  &-pageListHeader {
    font-weight: bolder;
    @include spaced;
    border-bottom: 1px solid var(--border-grey);
    line-height: 0;
    color: #b5b5b5;
    > span {
      background: white;
      padding: 0 0.5em; } }

  &-WelcomeScreen {
    text-align: center;
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .Icon {
      font-size: 100px;
      $animation: OrganizationAdminPage-spin 2s cubic-bezier(0.13, -0.01, 0, 1) 50ms;
      -webkit-animation: $animation;
      animation: $animation; }
    .Header {
      margin: var(--vertical-space) 0; } }

  &-MyNetworkPage,
  &-OtherNetworksPage {
    > .Header {
      margin-left: var(--horizontal-space);
      margin-right: var(--horizontal-space); } }
  &-MyNetworkPage {
    > .ToggleNetworkButton,
    > .Alert {
      margin-bottom: var(--vertical-space); } }

  &-WebhooksPage {
    .Loading {
      min-height: 200px; }
    &-listMember {
      @include padded;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:hover {
        background-color: var(--background-grey); } } } }


@keyframes OrganizationAdminPage-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
