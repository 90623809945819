$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.ContentBox {
  @include clearfix;
  background: white;
  @include contentBoxBorder;
  margin: 1px;
  @include spaceVertically;
  &-padded {
    padding: var(--vertical-padding) var(--horizontal-padding);
    @include removeVerticalMarginFromChildren; }
  &-noBorder {
    box-shadow: none; } }
