$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.TextInputWithPrefix {
  display: flex;
  align-items: center;
  border-radius: 4px;
  box-sizing: border-box;
  align-items: stretch;
  @include inputBorder;
  &:focus-within {
    @include inputFocusBorder; }
  > div:first-child {
    color: var(--greyed-out);
    display: flex;
    align-items: center;
    padding-left: 10px;
    white-space: pre;
    line-height: normal; }
  > .TextInput {
    border: none;
    flex-grow: 1;
    &:focus, &:focus-within {
      box-shadow: none; }
    > input {
      padding-left: 0; } } }


