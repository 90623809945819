$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationList {
  --member-height: 60px;
  &-Empty {
    margin: var(--vertical-space) var(--horizontal-space); }

  &-Members {
    position: relative;
    --length: 0;
    height: calc(var(--length) * var(--member-height));
    > * {
      --index: 0;
      position: absolute;
      height: var(--member-height);
      top: calc(var(--index) * var(--member-height));
      left: 0;
      right: 0;
      background-color: white; } }

  &-animateChanges &-Members > * {
    transition: top 200ms; }

  &-Member {
    height: var(--member-height, 60px);
    padding: 0 var(--horizontal-padding);

    &:after {
      content: " ";
      position: absolute;
      bottom: 0;
      left: 10px;
      right: 10px;
      border-bottom: 1px solid var(--border-grey-lightened); }

    // :last-child cant work because nested in unorderred parents
    &-last > *:after {
      display: none; }

    &:hover, &:focus, &:focus-within {
      background-color: var(--background-grey); }

    &-selected, &:active {
      &, &:hover, &:focus, &:focus-within {
        background-color: var(--normal-button-disabled-border-color); } }

    &-active {
      &:after {
        position: absolute;
        top: 20px;
        left: 10px;
        border: 7px solid transparent;
        border-left-color: var(--tru-blue);
        content: " "; } }

    .IconRow-buttons > {
      .Link, .Button {
        margin-left: 5px; } } } }
