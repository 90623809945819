$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.RichMedia {
  --feed-post-media-max-height: 700px;
  @include onScreensNarrowerThan('phablet') {
    --feed-post-media-max-height: 550px; }
  @include onScreensNarrowerThan('phone') {
    --feed-post-media-max-height: 400px; }
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;

  &-constrainHeight,
  &-constrainHeight &-box {
    max-height: var(--feed-post-media-max-height); }

  &-box {
    flex: 0 0;
    overflow-y: auto;
    > .PlainText {
      padding: var(--vertical-padding) var(--horizontal-padding); } }

  &-withFiles &-box {
    max-height: 100px;
    > .PlainText {
      min-height: 80px; } }

  > .MediaSlideshow {
    flex: 1 0;
    > .MediaSlideshow-slides {
      overflow: hidden;
      flex: 1 1 calc(var(--feed-post-media-max-height) - 100px); }
    > .NavDots {
      flex: 0 0 20px; } } }
