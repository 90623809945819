$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.TextAreaWithSwitch {
  position: relative;
  .TextArea textarea {
    padding-right: 56px; }
  > .Switch, .Tooltip {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 10px;
    margin: auto;
    display: flex;
    align-items: center;
    height: fit-content; }
  &-switchTooltip .Switch {
    cursor: not-allowed;
    .Button[disabled] {
      pointer-events: none; } } }
