$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.LeftDrawer {
  position: relative;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  &-menu, &-content {
    display: flex;
    flex-direction: column; }

  &-menu {
    flex: 1 1 100px;
    border-right: 1px solid var(--border-grey); }

  &-content {
    flex: 1 1 var(--max-content-width); }

  &-menu-header,
  &-content-header {
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: var(--horizontal-padding);
    border-bottom: 1px solid var(--border-grey); }

  // TODO remove the copy of this in
  //    src/a-portal/pages/OrganizationChatPage/index.sass
  &-Header {
    flex: 0 0 60px;
    height: 60px;
    display: flex;
    align-items: center;
    padding: var(--vertical-padding) var(--horizontal-padding);
    border-bottom: 1px solid var(--border-grey);
    > *:not(:last-child) {
      margin-right: var(--vertical-space); }
    > .Header {
      flex: 1 1; } }

  &-Body {
    flex: 1 1 calc(100% - 60px);
    overflow: auto; }

  @include onScreensWiderThan('phablet') {
    &-Header > &-BackButton {
      display: none; } }

  @include onScreensNarrowerThan('phablet') {
    flex-direction: column;
    &-menu, &-content {
      transition: left 200ms ease-out; }
    &-menu {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%; }
    &-content {
      position: relative;
      left: 100%; }

    &-open &-menu {
      left: -100%; }
    &-open &-content {
      left: 0; } } }
