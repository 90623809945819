$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.ChatNotificationsList {
  > .Header {
    align-self: center;
    flex-grow: 1; }
  > .IconRowList > .IconRow {
    > .IconRow-icon {
      padding-left: var(--horizontal-space);
      padding-right: calc(var(--horizontal-space) / 2);
      margin-right: 0; }
    > .IconRow-row {
      padding-left: 0;
      > *:first-child {
        margin-right: var(--horizontal-space); } }
    .ChatChannelListMember {
      padding-left: 0; }
    .NotificationBadge {
      margin-right: var(--horizontal-space); } } }
