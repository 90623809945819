$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.SingleLine {
  display: inline-flex;
  flex-direction: row;
  white-space: nowrap;
  max-width: 100%;
  flex-shrink: 1;
  overflow-y: auto;
  &-Shrink {
    flex-shrink: 1;
    overflow: hidden;
    text-overflow: ellipsis; } }
