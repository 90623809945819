$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationPage {
  --OrganizationPage-maxPageHeight: calc(100vh - var(--Layout-TopNav-height) - (var(--vertical-space) * 2));

  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  @include onScreensNarrowerThan('phablet') {
    --nav-height: 220px; }
  @include onScreensWiderThan('phablet') {
    --nav-height: 375px; }
  min-height: calc(100vh + var(--nav-height));
  @include onScreensNarrowerThan('tablet') {
    // used to find ideal scrol position when scrolling to feed post on published feed page
    --OrganizationPage-topNavScrollsAway: true;
    // so BannerNav-MobileNav doesnt cover content
    padding-bottom: var(--BannerNav-MobileHeight); }

  //TwoColumns-top-offset: (BannerNav.height + LayoutTopNav.height + padding)
  --TwoColumns-top-offset: calc(var(--Layout-TopNav-height) + var(--Layout-TopNav-visibleHeight) + var(--vertical-space));
  &-NameAndStats {
    margin: 0 calc(var(--horizontal-space) * 3) 0 var(--horizontal-space);
    &-name {
      display: block;
      > .Link {
        white-space: nowrap;
        > * {
          overflow: hidden;
          text-overflow: ellipsis; } } } }
  .BannerNav-DesktopNav {
    &-name {
      min-width: 0; }
    @include onScreensNarrowerThan('tablet') {
      .OrganizationPage-NameAndStats {
        margin-right: 4px; } }
    @include onScreensWiderThan('phone') {
      .OrganizationPage-navButton-Home,
      .OrganizationPage-navButton-Home-help {
        display: none; } } }

  .BannerNav-MobileNav {
    @include onScreensNarrowerThan('phone') {
      .PageTab {
        min-width: calc((100vw - ( var(--horizontal-space) * 2)) / 6);
        > div {
          display: flex;
          justify-content: flex-end;
          > .Icon {
            margin-top: auto;
            margin-bottom: auto; } } } } }


  @include isDataYogi {
    .BannerNav-DesktopNav {
      @include onScreensNarrowerThan('phablet') {
        flex-wrap: wrap;
        padding-bottom: var(--vertical-padding);
        &-name {
          order: 0;
          flex-basis: auto; }
        &-extraButtons {
          width: 100%;
          margin-top: var(--vertical-padding);
          display: flex;
          justify-content: flex-end; } } } }

  &-PageContent {
    min-height: var(--OrganizationPage-maxPageHeight); } }
