$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.RadioInputGroup {
  display: flex;
  flex-direction: column;
  &-radio {
    cursor: pointer;
    display: flex;
    align-items: center;
    &:not(:last-child) {
      padding-bottom: calc(var(--vertical-padding) / 2); }
    &-disabled {
      cursor: not-allowed;
      .RadioInputGroup-radio {
        &-label {
          color: var(--greyed-out-darkened); }
        &-labelColumn {
          opacity: .5; } } }
    &-label {
      margin-right: 10px;
      margin-left: 5px; }
    &-labelColumn {
      display: flex;
      flex-direction: column;
      margin-left: calc(var(--horizontal-space) / 2); }
    &-large input {
      height: 30px;
      width: 30px; } } }
