$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.TwoColumns {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  &-left, &-right, &-right > * {
    display: flex;
    flex-direction: column; }

  @include onScreensNarrowerThan('tablet') {
    --right-hidden: 1;
    --left-column-width: var(--full-width-minus-padding);
    --margin: 0px;
    &-right {
      display: none; } }

  @include onScreensWiderThan('tablet') {
    --right-hidden: 0;
    --left-column-width: var(--max-content-width);
    --margin: var(--horizontal-space); }

  --right-column-width: calc(100% - var(--left-column-width) - var(--horizontal-space));

  &-left {
    width: var(--left-column-width);
    margin-right: var(--margin); }

  &-right {
    width: var(--right-column-width);
    flex-wrap: nowrap;
    transition: margin-top 200ms;
    > * {
      flex: 1 1;
      position: -webkit-sticky;
      position: sticky;
      transition: top 200ms;
      top: var(--TwoColumns-top-offset);
      max-height: calc(100vh - var(--TwoColumns-top-offset) - var(--vertical-space));
      overflow: hidden;

      margin: 0 calc(var(--horizontal-space) * -1);
      padding: 0 var(--horizontal-space);
      > * {
        max-height: calc(100% - 2px);
        flex-shrink: 1; } } } }
