$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.FeedPostURLPreview {
  overflow: hidden;

  &-media {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: black;
    max-height: 400px;
    .Link, img {
      display: block; } }

  &-details {
    padding: var(--vertical-padding) var(--horizontal-padding);
    background-color: var(--background-grey-darkened); }

  &-hostname {
    display: block;
    color: var(--grey-text);
    > .Link {
      text-decoration: none;
      color: var(--grey-text); } }

  &-title {
    display: block;
    margin: calc(var(--vertical-padding) / 2) 0;
    > .Link {
      text-decoration: none;
      font-size: var(--xl-header-font-size);
      font-weight: var(--xl-header-font-weight); } }

  &-description {
    display: block;
    color: var(--grey-text);
    white-space: pre;
    overflow: hidden;
    text-overflow: ellipsis; } }

