$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.IconRowList {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  > .IconRow {
    position: relative;
    &:hover, &:focus, &:focus-within {
      background-color: var(--list-member-hover-background-color); }
    > .IconRow-icon,
    > .IconRow-row {
      padding-top: 5px;
      padding-bottom: 5px; }
    > .IconRow-icon {
      margin-right: 10px; } } }
