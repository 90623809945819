@import url("https://use.typekit.net/fsv8zne.css");
@import "style/mixins.sass";

@mixin base-theme(
  // font
  $default-font-size: 16px,
  $default-font-family: "proxima-nova, sans-serif",
  $default-font-weight: 400,
  $default-line-height: 1.2,
  $default-font-style: normal,
  $default-font-color: #2c3e50,
  $default-font: var(--default-font-weight) var(--default-font-size)/var(--default-line-height) var(--default-font-family),

  // colors
  $navy-blue: #204d8e,
  $dark-navy-blue: #163F70,
  $tru-blue: #177bdf,
  $border-grey: rgb(209, 211, 221),
  $boring-blue: #24394F,
  $grey-text: #747474,
  $greyed-out: #878C93,
  $error-red: #f56c6c,
  $background-grey: #F5F7FA,
  $unsaved-background-color: #bedacc,
  $unsaved-border-color: darken(#bedacc, 35%),
  $unsaved-and-disabled-border-color: lighten($unsaved-border-color, 25%),
  $unsaved-and-disabled-background-color: lighten($unsaved-background-color, 5%),
  $saved-background-color: $navy-blue,
  $disabled-input-background: #f5f7fa,
  $border-grey-lightened: lighten($border-grey, 10%),
  $border-grey-darkened: darken($border-grey, 7%),
  $greyed-out-darkened: darken($greyed-out, 7%),
  $background-grey-darkened: darken($background-grey, 7%),
  $input-focus-border-color: var(--tru-blue),
  $unsaved-background-color-darkened: darken($unsaved-background-color, 10%),
  $disabled-input-background-dark: darken($disabled-input-background, 5%),
  $disabled-input-background-darker: darken($disabled-input-background, 15%),
  $link-blue: #0e7cd6,
  $list-member-hover-background-color: var(--background-grey),
  $list-member-hover-background-color-dark: var(--dark-navy-blue),

  // button colors
  $subtle-button-text-color: $default-font-color,
  $subtle-button-border-color: $border-grey,
  $subtle-button-border-color-lightened: lighten($border-grey, 40%),
  $subtle-button-background-color: white,
  $subtle-button-background-color-lightened: white,
  $subtle-button-background-color-darkened: white,
  $subtle-button-hover-border-color: $default-font-color,
  $subtle-button-hover-background-color: white,
  $subtle-button-disabled-text-color: lighten($default-font-color, 40%),
  $subtle-button-disabled-border-color: lighten($border-grey, 40%),
  $subtle-button-disabled-background-color: white,

  $normal-button-text-color: $tru-blue,
  $normal-button-border-color: $tru-blue,
  $normal-button-border-color-lightened: white,
  $normal-button-background-color: white,
  $normal-button-background-color-lightened: white,
  $normal-button-background-color-darkened: white,
  $normal-button-hover-border-color: $tru-blue,
  $normal-button-hover-background-color: white,
  $normal-button-disabled-text-color: lighten($default-font-color, 40%),
  $normal-button-disabled-border-color: lighten($tru-blue, 40%),
  $normal-button-disabled-background-color: white,

  $primary-button-text-color: #fff,
  $primary-button-border-color: $tru-blue,
  $primary-button-background-color: $tru-blue,
  $primary-button-border-color-lightened: desaturate(lighten($primary-button-border-color, 40%), 40%),
  $primary-button-background-color-lightened: desaturate(lighten($primary-button-background-color, 40%), 40%),
  $primary-button-background-color-darkened: darken($primary-button-background-color, 5%),
  $primary-button-hover-border-color: darken($primary-button-border-color, 5%),
  $primary-button-hover-background-color: darken($primary-button-background-color, 5%),
  $primary-button-disabled-text-color: transparentize($primary-button-text-color, 0.5),
  $primary-button-disabled-border-color: #8ebdf0,
  $primary-button-disabled-background-color: #8ebdf0,

  $success-button-text-color: #fff,
  $success-button-border-color:  #208d60,
  $success-button-background-color:  #208d60,
  $success-button-border-color-lightened: desaturate(lighten($success-button-border-color, 40%), 40%),
  $success-button-background-color-lightened: desaturate(lighten($success-button-background-color, 40%), 40%),
  $success-button-background-color-darkened: darken($success-button-background-color, 5%),
  $success-button-hover-border-color: darken($success-button-border-color, 5%),
  $success-button-hover-background-color: darken($success-button-background-color, 5%),
  $success-button-disabled-text-color: transparentize($success-button-text-color, 0.5),
  $success-button-disabled-border-color: #9ecab5,
  $success-button-disabled-background-color: #9ecab5,

  $page-column-width: $RESPONSIVE_BREAKPOINT_DESKTOP,
  $side-nav-text-color: white,

  $header-font-color: #2e2e2e,
  $xs-header-font-size: 14px,
  $xs-header-font-weight: 300,
  $sm-header-font-size: 14px,
  $sm-header-font-weight: 300,
  $md-header-font-size: 16px,
  $md-header-font-weight: 300,
  $lg-header-font-size: 18px,
  $lg-header-font-weight: 400,
  $xl-header-font-size: 20px,
  $xl-header-font-weight: 400,
  $xxl-header-font-size: 30px,
  $xxl-header-font-weight: 400,

  $collapse-animation-duration: 200ms,

  $vertical-padding: 15px,
  $horizontal-padding: 15px,
  $vertical-space: 15px,
  $horizontal-space: 15px,
  $content-box-margin: $horizontal-space,
  $content-box-box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.10)
){

  --default-font-size: #{$default-font-size};
  --default-font-family: #{$default-font-family};
  --default-font-weight: #{$default-font-weight};
  --default-line-height: #{$default-line-height};
  --default-font-style: #{$default-font-style};
  --default-font-color: #{$default-font-color};
  --default-font: #{$default-font};

  --navy-blue: #{$navy-blue};
  --dark-navy-blue: #{$dark-navy-blue};
  --tru-blue: #{$tru-blue};
  --border-grey: #{$border-grey};
  --boring-blue: #{$boring-blue};
  --grey-text: #{$grey-text};
  --greyed-out: #{$greyed-out};
  --error-red: #{$error-red};
  --background-grey: #{$background-grey};
  --unsaved-background-color: #{$unsaved-background-color};
  --unsaved-border-color: #{$unsaved-border-color};
  --unsaved-and-disabled-border-color: #{$unsaved-and-disabled-border-color};
  --unsaved-and-disabled-background-color: #{$unsaved-and-disabled-background-color};
  --saved-background-color: #{$saved-background-color};
  --disabled-input-background: #{$disabled-input-background};
  --border-grey-lightened: #{$border-grey-lightened};
  --border-grey-darkened: #{$border-grey-darkened};
  --greyed-out-darkened: #{$greyed-out-darkened};
  --background-grey-darkened: #{$background-grey-darkened};
  --input-focus-border-color: #{$input-focus-border-color};
  --unsaved-background-color-darkened: #{$unsaved-background-color-darkened};
  --disabled-input-background-dark: #{$disabled-input-background-dark};
  --disabled-input-background-darker: #{$disabled-input-background-darker};
  --link-blue: #{$link-blue};
  --list-member-hover-background-color: #{$list-member-hover-background-color};
  --list-member-hover-background-color-dark: #{$list-member-hover-background-color-dark};

  --subtle-button-text-color: #{$subtle-button-text-color};
  --subtle-button-border-color: #{$subtle-button-border-color};
  --subtle-button-border-color-lightened: #{$subtle-button-border-color-lightened};
  --subtle-button-background-color: #{$subtle-button-background-color};
  --subtle-button-background-color-lightened: #{$subtle-button-background-color-lightened};
  --subtle-button-background-color-darkened: #{$subtle-button-background-color-darkened};
  --subtle-button-hover-border-color: #{$subtle-button-hover-border-color};
  --subtle-button-hover-background-color: #{$subtle-button-hover-background-color};
  --subtle-button-disabled-text-color: #{$subtle-button-disabled-text-color};
  --subtle-button-disabled-border-color: #{$subtle-button-disabled-border-color};
  --subtle-button-disabled-background-color: #{$subtle-button-disabled-background-color};

  --normal-button-text-color: #{$normal-button-text-color};
  --normal-button-border-color: #{$normal-button-border-color};
  --normal-button-border-color-lightened: #{$normal-button-border-color-lightened};
  --normal-button-background-color: #{$normal-button-background-color};
  --normal-button-background-color-lightened: #{$normal-button-background-color-lightened};
  --normal-button-background-color-darkened: #{$normal-button-background-color-darkened};
  --normal-button-hover-border-color: #{$normal-button-hover-border-color};
  --normal-button-hover-background-color: #{$normal-button-hover-background-color};
  --normal-button-disabled-text-color: #{$normal-button-disabled-text-color};
  --normal-button-disabled-border-color: #{$normal-button-disabled-border-color};
  --normal-button-disabled-background-color: #{$normal-button-disabled-background-color};
  --primary-button-text-color: #{$primary-button-text-color};
  --primary-button-border-color: #{$primary-button-border-color};
  --primary-button-background-color: #{$primary-button-background-color};
  --primary-button-border-color-lightened: #{$primary-button-border-color-lightened};
  --primary-button-background-color-lightened: #{$primary-button-background-color-lightened};
  --primary-button-background-color-darkened: #{$primary-button-background-color-darkened};
  --primary-button-hover-border-color: #{$primary-button-hover-border-color};
  --primary-button-hover-background-color: #{$primary-button-hover-background-color};
  --primary-button-disabled-text-color: #{$primary-button-disabled-text-color};
  --primary-button-disabled-border-color: #{$primary-button-disabled-border-color};
  --primary-button-disabled-background-color: #{$primary-button-disabled-background-color};
  --success-button-text-color: #{$success-button-text-color};
  --success-button-border-color: #{$success-button-border-color};
  --success-button-background-color: #{$success-button-background-color};
  --success-button-border-color-lightened: #{$success-button-border-color-lightened};
  --success-button-background-color-lightened: #{$success-button-background-color-lightened};
  --success-button-background-color-darkened: #{$success-button-background-color-darkened};
  --success-button-hover-border-color: #{$success-button-hover-border-color};
  --success-button-hover-background-color: #{$success-button-hover-background-color};
  --success-button-disabled-text-color: #{$success-button-disabled-text-color};
  --success-button-disabled-border-color: #{$success-button-disabled-border-color};
  --success-button-disabled-background-color: #{$success-button-disabled-background-color};

  --page-column-width: #{$page-column-width};
  --full-width-minus-padding: calc(100vw - calc(var(--horizontal-space) * 2));
  --max-page-column-width: min(var(--page-column-width), var(--full-width-minus-padding));
  // the max width we ever want to show media content like feed posts
  --max-content-width: calc(var(--max-page-column-width) - 38.197%);

  @include onScreensNarrowerThan('tablet'){
    --content-column-width: 100%;
  }
  @include onScreensWiderThan('tablet'){
    --content-column-width: calc(var(--page-column-width) - 38.197%);
  }
  --side-nav-text-color: #{$side-nav-text-color};
  --header-font-color: #{$header-font-color};
  --xs-header-font-size: #{$xs-header-font-size};
  --xs-header-font-weight: #{$xs-header-font-weight};
  --sm-header-font-size: #{$sm-header-font-size};
  --sm-header-font-weight: #{$sm-header-font-weight};
  --md-header-font-size: #{$md-header-font-size};
  --md-header-font-weight: #{$md-header-font-weight};
  --lg-header-font-size: #{$lg-header-font-size};
  --lg-header-font-weight: #{$lg-header-font-weight};
  --xl-header-font-size: #{$xl-header-font-size};
  --xl-header-font-weight: #{$xl-header-font-weight};
  --xxl-header-font-size: #{$xxl-header-font-size};
  --xxl-header-font-weight: #{$xxl-header-font-weight};
  --collapse-animation-duration: #{$collapse-animation-duration};
  --vertical-padding: #{$vertical-padding};
  --horizontal-padding: #{$horizontal-padding};
  --vertical-space: #{$vertical-space};
  --horizontal-space: #{$horizontal-space};
  --content-box-margin: #{$content-box-margin};
  --content-box-box-shadow: #{$content-box-box-shadow};
  --BannerNav-MobileHeight: 50px;

  // inheritable styles
  font: var(--default-font);
  font-family: var(--default-font-family);
  -moz-osx-font-smoothing: grayscale;
  color: var(--default-font-color);
}
