$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.UserProfilePage {
  // min-height: -webkit-fill-available
  flex: 1 1 auto;
  @include onScreensNarrowerThan('tablet') {
    padding-bottom: var(--BannerNav-MobileHeight); }

  &-navName {
    padding: var(--vertical-space) var(--horizontal-space); }

  > .EditableEndUserBanner .Overlay {
    box-shadow: none; }

  &-HoverEditIcon {
    position: relative; }

  &-HoverEditIcon:hover > &-HoverEditIcon-Icon {
      opacity: 1; }

  &-HoverEditIcon-Icon {
    z-index: 100;
    position: absolute;
    transition: opacity 200ms ease;
    opacity: 0;
    top: 0;
    right: 0; } }
