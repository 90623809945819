$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.FullPageKnot {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background-color: var(--navy-blue);

  > .Card {
    box-shadow: 0 12px 30px 0 rgba(0, 0, 0, 0.5);
    max-width: 615px; } // TODO dry this up with --main-content-width in AliceLayout

  &-knot {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    pointer-events: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    opacity: .5;
    div {
      width: 140%;
      transform: rotateY(47deg) rotateZ(10deg) rotateX(-44deg); }
    svg {
      height: 100%;
      width: 100%;
      -webkit-animation: FullPageKnot-knot-spin 400s infinite linear -100s;
      animation: FullPageKnot-knot-spin 400s infinite linear -100s; } }

  @include onScreensNarrowerThan('phone') {
    padding: 20px 0 0;
    display: block;
    background-color: white;
    &-knot {
      display: none; }
    > .Card {
      width: auto;
      padding-bottom: 40px;
      margin-bottom: 0;
      box-shadow: none;
      border: none;
      > .Card-Body {
        padding-top: 10px; } } }

  @-webkit-keyframes FullPageKnot-knot-spin {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg); }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg); } } }
