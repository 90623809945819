$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.CountdownToTime {
  &-done {
    animation-name: CountdownToTime-done;
    animation-duration: 1s;
    animation-fill-mode: both; } }

@keyframes CountdownToTime-done {
  0%, 50%, 100% {
    opacity: 1; }
  25%, 75% {
    opacity: 0; } }
