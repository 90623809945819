$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.EndUserAvatarStack {
  user-select: none;
  display: inline-flex;
  flex-direction: row;
  overflow: hidden;
  flex-shrink: 0;
  position: relative;

  &-sm {
    height: 33px;
    width: 33px; }
  &-md {
    height: $AVATAR_HEIGHT / 2;
    width: $AVATAR_WIDTH / 2; }
  &-lg {
    height: $AVATAR_HEIGHT;
    width: $AVATAR_WIDTH; }

  > .EndUserAvatar {
    &:first-child {
      position: relative; }
    &:not(:first-child) {
      position: absolute; }
    top: var(--offset);
    left: var(--offset);
    &-sm {
      box-shadow: 0 0 0 2px white; }
    &-md {
      box-shadow: 0 0 0 4px white; }
    &-lg {
      box-shadow: 0 0 0 5px white; } }

  &-two > .EndUserAvatar {
    transform: scale3d(0.8, 0.8, 0.8);
    &-sm {
      transform: scale3d(0.7, 0.7, 0.7);
      &:nth-child(1) {
        --offset: -3px; }
      &:nth-child(2) {
        --offset: 3px; } }
    &-md {
      &:nth-child(1) {
        --offset: -7px; }
      &:nth-child(2) {
        --offset: 7px; } }
    &-lg {
      &:nth-child(1) {
        --offset: -14px; }
      &:nth-child(2) {
        --offset: 14px; } } }

  &-three > .EndUserAvatar,
  &-more > .EndUserAvatar {
    transform: scale3d(0.7, 0.7, 0.7);
    &-sm {
      &:nth-child(1) {
        --offset: -4px; }
      &:nth-child(2) {
        --offset: 0; }
      &:nth-child(3) {
        --offset: 4px; } }
    &-md {
      &:nth-child(1) {
        --offset: -11px; }
      &:nth-child(2) {
        --offset: 0; }
      &:nth-child(3) {
        --offset: 11px; } }
    &-lg {
      &:nth-child(1) {
        --offset: -22px; }
      &:nth-child(2) {
        --offset: 0; }
      &:nth-child(3) {
        top: 22px;
        left: 22px; } } }

  & > &-length {
    position: absolute;
    bottom: 0;
    right: 0;
    color: black;
    font-weight: 900;
    min-width: 1.2em;
    text-align: center;
    border-radius: 50%;
    background-color: white;
    text-shadow: 0 0 5px white; }

  &-sm > &-length {
    font-size: 10px; }
  &-md > &-length {
    font-size: 20px; }
  &-lg > &-length {
    font-size: 30px; } }
