$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationDescription {
  &, &-input {
    position: relative; }

  &, & > .ContentBox {
    min-height: 40px; }

  > .EditButton {
    position: absolute;
    top: calc(var(--vertical-space) / 2);
    right: calc(var(--horizontal-space) / 2); }

  .WYSIWYGContent {
    overflow: hidden;
    @include removeVerticalMarginFromChildren; }

  margin-bottom: 0;
  transition: margin-bottom 200ms ease-in-out;
  &-editing {
    margin-bottom: 56px; }

  &-buttons {
    position: absolute;
    top: calc(100% + var(--vertical-space));
    right: 0; }

  .WYSIWYGInput .WYSIWYGEditor {
    position: static; } }
