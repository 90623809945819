$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.HeaderWithButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  > .Header {
    flex: 1 1;
    text-overflow: ellipsis; }
  > .ButtonRow {
    flex: 0 0 auto;
    white-space: nowrap; }

  &-underlined {
    border-bottom: 1px solid var(--border-grey-lightened);
    padding-bottom: 5px;
    margin-bottom: 15px; } }
