$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.AuthPage {
  @include onScreensWiderThan('phablet') {
    &-Forms {
      max-width: 550px;
      min-width: 350px; } }
  @include onScreensNarrowerThan('phablet') {
    flex-grow: 1;
    margin-left: -15px;
    margin-right: -15px;
    margin-top: calc(var(--Layout-top-padding) * -1);
    margin-bottom: calc(var(--vertical-padding) * -1);
    background-color: white;
    &-Forms {
      // width: unquote('min(100vw, 350px)')
      margin-top: var(--Layout-top-padding);
      box-shadow: none;
      max-width: none;
      border: none; } } }
