$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.ParallelContentBoxes {
  .ContentBox + &, + .ContentBox {
    margin-top: var(--vertical-space); }
  @include onScreensWiderThan('tablet') {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    > * {
      width: calc(50% - calc(var(--horizontal-space) / 2));
      margin-top: 1px !important; } } } // override `.ContentBox + .ContentBox`
