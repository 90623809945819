$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.RichMediaInput {
  position: relative;

  .FileButton {
    opacity: .75;
    &:hover {
      opacity: 1; } }
  &:focus-within .FileButton {
    opacity: 1; }

  &-rel {
    position: relative; }

  &-footer {
    display: flex;
    > span {
      flex-grow: 1; } }

  &-input {
    overflow: scroll;
    max-height: 400px; }

  &-withFiles &-input {
    max-height: 100px; }

  .TextArea {
    --TextArea-vertical-padding: var(--vertical-padding);
    --TextArea-horizontal-padding: var(--horizontal-padding);
    // --line-height: var(--default-line-height) CANT DO must be px
    --line-height: 19.2px;
    margin: 0;
    font-size: var(--default-font-size);
    &, * {
      white-space: pre-wrap;
      font-size: inherit;
      font-family: var(--default-font-family);
      -moz-osx-font-smoothing: grayscale;
      color: var(--default-font-color);
      border-width: 0; } }

  .FileButton {
    &:active, &:focus, &:focus-within {
      outline: none;
      box-shadow: none;
      .Icon {
        color: var(--tru-blue); } }
    .Icon {
      margin: 2px 2px 0 2px; } }

  .RemoveButton {
    position: absolute;
    top: -5px;
    right: -5px;
    // height: 20px
    // width: 20px
    // z-index: 10
    // .Icon::before
    //   background: white
    //   height: 15px
    //   width: 15px
    //   line-height: 15px
    //   margin: 2.5px
    //   border-radius: 50%
 }    //   box-shadow: 0px 0px 5px 2px white

  // +onDevicesWithHover
  //   .RemoveButton
  //     opacity: 0
  //     transition: opacity 200ms linear
  //   &-File-failed .RemoveButton,
  //   &-Files > *:hover > .RemoveButton,
  //   &-urlPreview:hover .RemoveButton
  //     opacity: 1

  // &-urlPreview, &-Files
  //   margin: var(--vertical-space) 0

  &-urlPreview {
    position: relative;
    .FeedPostURLPreview {
      overflow: hidden; } }

  &-Files {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    grid-gap: 20px;
    align-items: normal;
    > * {
      position: relative;
      background-color: white;
      min-width: 100px;
      min-height: 100px;
      max-width: 200px;
      max-height: 200px; } }

  &-FileMedia {
    img {
      pointer-events: none; } }

  &-fileError {
    background: grey;
    height: 100%;
    width: 100%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--vertical-padding) var(--horizontal-padding); }

  .FilesUploadDroparea-overlay {
    // top: -15px
    // left: -15px
    // bottom: -15px
    // right: -15px
    // height: auto
    // width: auto
    > .Icon {
      font-size: 100px; } } }
