$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.HeaderedContentWithUnsavedAndHelpButton {
  &-headerRow {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: flex-start; }
  &-header {
    height: 100%;
    margin-right: 5px; }
  &-filler {
    flex: 1 1;
    height: 100%; }
  .AlertModal-content {
    white-space: normal; } }
