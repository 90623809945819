$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins";

.TabsHeaders {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid var(--border-grey);
  margin-bottom: 15px;
  margin-top: -2px;
  &-fullWidth {
    > * {
      margin: 0;
      flex-grow: 1; } }
  > * {
    &:not(:last-child) {
      margin-right: 20px; } }
  &-Header {
    display: block;
    padding-bottom: 4px;
    color: var(--greyed-out);
    text-align: center;
    position: relative;
    top: 2px;
    cursor: pointer;
    &:hover {
      color: var(--greyed-out-darkened); }
    &-Selected {
      border-bottom: 3px solid var(--tru-blue);
      &, &:hover {
        color: var(--navy-blue); } } }
  &-padded &-Header {
    @include padded; } }
