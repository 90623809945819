$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.RemoveButton {
  height: 20px;
  width: 20px;
  z-index: 10;
  .Icon::before {
    background: white;
    height: 15px;
    width: 15px;
    line-height: 15px;
    margin: 2.5px;
    border-radius: 50%;
    box-shadow: 0px 0px 5px 2px white; } }
