$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.NotificationBadge {
  pointer-events: none;
  @include circledAlert;
  display: inline-block;
  background-color: red;
  color: white;
  font-weight: 700;
  opacity: 1;
  transform: scale(1);
  $ms: 200ms;
  $bounce: cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: opacity $ms $bounce, transform $ms $bounce;
  &-zero {
    opacity: 0;
    transform: scale(0.25); }
  &-topRight {
    z-index: 10;
    position: absolute;
    top: -10px;
    right: -10px; } }
