$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationPageSubnav {
  margin-bottom: var(--vertical-space);
  + .ContentBox {
    margin-top: 0; }
  > .Navbar {
    position: relative;
    @include onScreensNarrowerThan('phone') {
      padding-top: 0; }
    > .Header {
      margin-right: auto;
      > .Icon:first-child {
        margin-right: 0.5em; }
      @include onScreensNarrowerThan('phone') {
        padding-right: 27px; } } } }
