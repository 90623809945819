$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.Pill {
  display: inline-block;
  padding: .25em .6em;
  font-size: 90%;
  font-weight: 800;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
  text-overflow: clip;
  &-primary {
    color: var(--primary-button-text-color);
    background-color: var(--primary-button-background-color); }
  &-success {
    color: var(--success-button-text-color);
    background-color: var(--success-button-background-color); }
  &-info {
    color: #5a5e66;
    background-color: #d3d3d3; } }
