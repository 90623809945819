$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.FeedPostFormWithPreview {
  overflow: hidden;
  max-height: calc(100vh - 120px);
  // wow this is silly and needs to be dried up
  min-width: unquote('min(calc(var(--page-column-width) * 0.61803), calc(100vw - (var(--horizontal-space) * 4)))');
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  &-ScrollArea {
    overflow: auto;
    flex: 1 1;
    padding: var(--vertical-padding) var(--horizontal-padding) 0 var(--horizontal-padding); }
  &-ButtonRow {
    align-items: baseline;
    padding: var(--vertical-padding) var(--horizontal-padding); } }

