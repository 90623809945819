$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.InfiniteScrollDown {
  &-transition {
    &-enter {
      opacity: 0;
      transition: opacity 300ms ease-in; }
    &-enter-active {
      opacity: 1; }
    &-exit {
      opacity: 1;
      transition: opacity 300ms ease-in; }
    &-exit-active {
      opacity: 0; } }

  &-Footer {
    padding: var(--vertical-padding) var(--horizontal-padding); }

  &-FullyLoaded {
    text-align: center;
    font-weight: bolder;
    color: var(--grey-text);
    font-style: italic; } }
