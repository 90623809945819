$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.UsernameInput {
  position: relative;
  &-status {
    position: absolute;
    top: 0;
    right: calc( var(--horizontal-space) / 2 );
    bottom: 0;
    display: flex;
    align-items: center;
    .Icon-checkmark {
      color: var(--success-button-background-color); }
    .Icon-cancel-circled {
      color: var(--error-red); } } }
