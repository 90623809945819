$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.ChatChannelListMember {
  position: relative;
  display: flex;
  align-items: center;
  padding: calc(var(--vertical-padding) / 2) 0;
  padding-left: calc(var(--horizontal-padding) / 2);
  .TimeAgo {
    flex-shrink: 0;
    font-size: 80%;
    white-space: nowrap; }
  &-details {
    flex: 1 1 auto;
    overflow: hidden;
    margin-left: calc(var(--horizontal-padding) / 2);
    > * {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; } }
  &-preview {
    display: flex;
    align-items: center;
    line-height: 1.5;
    > span:nth-child(1) {
      overflow: hidden;
      text-overflow: ellipsis; }
    > span:nth-child(2) {
      margin: 0 0.2em; } } }

