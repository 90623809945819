$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.ProgressPie {
  --ProgressPie-background-color: white;
  --ProgressPie-light-color: var(--background-grey-darkened);
  --ProgressPie-dark-color: var(--tru-blue);

  &-sm {
    --ProgressPie-size: 35px;
    --ProgressPie-font-size: 15px;
    --ProgressPie-line-height: 25px; }

  &-md {
    --ProgressPie-size: 60px;
    --ProgressPie-font-size: 20px;
    --ProgressPie-line-height: 40px; }

  &-lg {
    --ProgressPie-size: 120px;
    --ProgressPie-font-size: 50px;
    --ProgressPie-line-height: 80px; }

  &-xl {
    --ProgressPie-size: 170px;
    --ProgressPie-font-size: 75px;
    --ProgressPie-line-height: 120px; }

  position: relative;
  display: inline-block;
  width: var(--ProgressPie-size);
  height: var(--ProgressPie-size);
  border-radius: 50%;
  background: var(--ProgressPie-light-color);
  background-image: linear-gradient(to right, transparent 50%, var(--ProgressPie-dark-color) 0);

  &::before {
    content: '';
    display: block;
    margin-left: 50%;
    height: 100%;
    border-radius: 0 100% 100% 0 / 50%;
    background-color: inherit;
    transform-origin: left; }

  &::after {
    content: attr(data-value);
    background: var(--ProgressPie-background-color);
    position: absolute;
    width: 70%;
    height: 70%;
    margin: auto;
    border-radius: 50%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    text-align: center;
    font-size: var(--ProgressPie-font-size);
    line-height: var(--ProgressPie-line-height); }

  @for $i from 0 through 50 {
    &[data-value="#{$i}"]:before {
      transform: rotate(#{$i/100}turn); } }

  @for $i from 51 through 100 {
    &[data-value="#{$i}"]:before {
      background-color: var(--ProgressPie-dark-color);
      transform: rotate(#{$i/100 - .5}turn); } } }

