$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Captcha {
  display: flex;
  align-items: center;
  flex-direction: column;

  &-stage {
    position: relative;
    > .Button {
      position: absolute;
      top: 0;
      right: 0;
      padding: 2px 5px;
      font-size: smaller;
      text-shadow: 0 0 4px white; } }

  &-Image, .Loading {
    margin: 0;
    height: 100px;
    width: 340px;
    @include inputBorder;
    background-color: #cadef6;
    border-radius: 4px;
    overflow: hidden; }

  .TextInput {
    margin-top: var(--vertical-space);
    input {
      text-align: center; } } }
