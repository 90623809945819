$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "~normalize.css/normalize.css";
@import "./mixins.sass";
@import "./responsive.scss";

// http://www.paulirish.com/2012/box-sizing-border-box-ftw/
html {
  box-sizing: border-box;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  min-width: 320px;
  // fix lame bottom scrollbars showing up on windows
  overflow-x: hidden; }

*, *:before, *:after {
  // box-sizing: inherit
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility; }

body {
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  min-height: 100vh;
  max-height: 100vh;
  min-width: 320px;
  max-width: 100vw; }

// removes chrome yellow autofill background
@-webkit-keyframes autofill {
  to {
    background: transparent; } }

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both; }

textarea {
  -webkit-appearance: none;
  &:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both; } }


@import "./grid";
@import "classes/fullpage";

img {
  max-width: 100%; }

p + p {
  margin-top: 0; }

strong {
  font-weight: bold; }

html.development body::before {
  pointer-events: none;
  z-index: 99999;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0 1px;
  font-size: 10px;
  background: black;
  color: white;
  content: var(--media-breakpoint-name, "?????"); }

.undefined {
  outine-color: red;
  outine-style: double;
  outine-width: 10px;
  outline-offset: -5px; }

.clearfix {
  @include clearfix; }
