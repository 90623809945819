$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.BounceArrow {
  &-active {
    &, &:not([disabled]):hover {
      color: var(--tru-blue); } }
  &:before {
    font-size: 120%; }

  &-up {
    animation-fill-mode: both;
    animation: BounceArrow-up 1s; }

  &-down {
    animation-fill-mode: both;
    animation: BounceArrow-down 1s; } }


@keyframes BounceArrow-up {
  0%, 30%, 60%, 100% {
    transform: translateY(0); }
  20% {
    transform: translateY(-7px); }
  40% {
    transform: translateY(-4px); } }

@keyframes BounceArrow-down {
  0%, 30%, 60%, 100% {
    transform: translateY(0); }
  20% {
    transform: translateY(7px); }
  40% {
    transform: translateY(4px); } }
