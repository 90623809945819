$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.ButtonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  > {
    .Button, .ButtonWithDropdown, .ButtonGroup, .Link, .InterfaceHelp {
      &:not(:first-child) {
        margin-left: calc(var(--horizontal-space) / 2); } } } }
