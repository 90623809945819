$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.TruLogo {
  user-select: none;
  $aspect-ratio: 160px / 311px;
  @mixin size($width) {
    height: $aspect-ratio * $width;
    width: $width; }
  &-sm {
    @include size(64px); }
  &-md {
    @include size(96px); }
  &-lg {
    @include size(160px); } }
