$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.OrganizationJoinModal {
  max-width: 650px;
  width: 100%;

  .OrganizationIcon {
    padding-bottom: var(--vertical-padding); }
  .ButtonRow {
    padding-top: var(--vertical-padding);
    justify-content: center; } }
