$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.AppError {
  &-reportStatus {
    font-size: 80%;
    font-style: italic; }

  &-Details {
    white-space: pre;
    background-color: black;
    color: white;
    padding: 1em;
    overflow: scroll;
    margin-bottom: var(--vertical-space); } }


