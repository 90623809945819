$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins";

.OrganizationSearch {
  > .SearchInput {
    margin-bottom: var(--vertical-space);
    margin-left: var(--horizontal-space);
    margin-right: var(--horizontal-space); }
  &-NoResults .Jlinx {
    margin-top: var(--vertical-space);
    height: 400px;
    max-height: unquote('max(20vh, 200px)');
    @include noSelectOrDrag; } }
