$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.OrganizationAnnouncements {
  .StyleishModal-body > & {
    min-width: unquote("min(600px, 90vw)");
    @include padded; }

  &, &-main, &-Slideshow {
    display: flex;
    flex-direction: column; }

  &-Button {
    position: relative;
    .NotificationBadge {
      position: absolute;
      top: -20px;
      right: -10px; } }

  &-Modal {
    .StyleishModal-body > input:first-child {
      position: fixed;
      top: -999em;
      left: -999em; } }

  &-main {
    flex: 1 1;
    > .Loading {
      min-height: 300px; } }

  &-editButtons {
    padding: calc(var(--vertical-padding) / 2) var(--horizontal-padding); }

  &-navButtons {
    padding-top: calc(var(--vertical-padding) / 2);
    justify-content: center;
    > .IconButton {
      &:first-child, &:last-child {
        color: var(--grey-text); } } }

  &-Announcement {
    position: relative;
    > .WYSIWYGContent {
      @include removeVerticalMarginFromChildren; }
    > .ButtonRow {
      position: absolute;
      top: 0;
      right: 0; }
    > .Header {
      margin-top: var(--vertical-space);
      font-size: 80%;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: flex-end;
      padding: 0 var(--horizontal-padding); } }

  &-emptyHeader {
    margin: var(--vertical-space) 0; }
  &-Editor {
    > .Header,
    > .Alert {
      margin-bottom: var(--vertical-space); } } }
