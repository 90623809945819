$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.CropImageInputFormRow {
  flex-wrap: wrap;
  > .Form-Item:not(:last-child) {
    @include onScreensWiderThan('phone') {
      flex: 0 0 auto; } }
  > *:last-child {
    flex: 2 2;
    > .Form-Item {
      margin-right: 0; } } }
