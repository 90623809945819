$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.Spotlight {
  --Spotlight-background-color: rgba(0, 0, 0, 0.5);
  z-index: 5001;
  pointer-events: none;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  width: 100vw;
  > * {
    position: absolute; }

  &-animated {
    > * {
      $ms: 200ms;
      $easing: ease-in-out;
      transition: top $ms $easing, left $ms $easing, bottom $ms $easing, right $ms $easing; } }

  --spot-top: 0px;
  --spot-left: 0px;
  --spot-height: 0px;
  --spot-width: 0px;
  --spot-bottom: calc(100vh - var(--spot-top, 0px) - var(--spot-height, 0px));
  --spot-right: calc(100vw - var(--spot-left, 0px) - var(--spot-width, 0px));

  &-top,
  &-left,
  &-bottom,
  &-right {
    background-color: var(--Spotlight-background-color); }
  &-top, &-bottom {
    left: 0;
    right: 0; }
  &-left, &-right {
    top: var(--spot-top, auto);
    bottom: var(--spot-bottom, auto); }
  &-top {
    top: 0;
    bottom: calc(100vh - var(--spot-top, 0px)); }
  &-left {
    left: 0;
    right: calc(100vw - var(--spot-left, 0px)); }
  &-bottom {
    top: calc(100vh - var(--spot-bottom, 0px));
    bottom: 0; }
  &-right {
    left: calc(100vw - var(--spot-right, 0px));
    right: 0; }
  &-spot {
    top: var(--spot-top, auto);
    left: var(--spot-left, auto);
    bottom: var(--spot-bottom, auto);
    right: var(--spot-right, auto); }
  &-square &-spot {
    border-radius: 0%;
    box-shadow: inset 0 0 5px 5px var(--Spotlight-background-color); }
  &:not(&-square) &-spot {
    background: radial-gradient(closest-side, transparent 0%, transparent 95%, var(--Spotlight-background-color) 100%, var(--Spotlight-background-color) 100%); } }


