$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.NavDots {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-y: hidden;
  overflow-x: auto;
  justify-content: center;
  align-items: center;
  padding: calc(var(--vertical-space) / 2) 0;
  > button {
    display: block;
    padding: 3px;
    margin: 0 1px;
    &, &:focus {
      outline: none; }
    > div {
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: var(--grey-text); }

    &[data-selected] > div {
      background-color: var(--tru-blue); }
    &:focus, &:focus-within {
      > div {
        @include inputFocusBorder; } } } }
