$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.IconRowAccordion {
  display: flex;
  flex-direction: column;
  &.Header {
    padding: var(--vertical-space) var(--horizontal-space); }
  > * {
    position: relative;

    & { // NOTE copied from OrganizationList
      &:not(:last-child):after {
        content: " ";
        position: absolute;
        bottom: -1px;
        left: 10px;
        right: 10px;
        border-bottom: 1px solid var(--border-grey-lightened); }
      > .IconRow {
        padding: calc(var(--vertical-padding) / 4) var(--horizontal-padding);
        height: 60px; } }

    > *:last-child {
      position: relative;
      overflow: hidden;
      --height: 0px;
      height: var(--height);
      transition: height 200ms ease-out;
      > div {
        overflow: hidden; } } } }
