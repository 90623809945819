$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.FeedPostForm {

  --FeedPostForm-border-radius: 3px;
  &-forForum {
    --FeedPostForm-border-radius: 20px; }

  &-Create {
    border-radius: var(--FeedPostForm-border-radius); }

  &-PostAsDropdown {
    display: flex;
    align-items: center; }

  &-top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
    > * {
      @include padded; }
    > label {
      display: flex;
      align-items: center;
      > .Form-Label {
        margin-right: 0.5em; } }
    &-options {
      display: flex;
      &-selects {
        padding-left: var(--horizontal-space);
        &-richMediaToggle {
          padding-left: 0; } } } }

  .EndUserAvatar, .OrganizationIcon {
    height: 50px;
    width: 50px; }

  &-PostAsDropdown {
    .Header {
      max-width: 20vw; }
    .DropdownAsHeader {
      padding-left: 0;
      padding-top: 0;
      padding-bottom: 0; } }

  &-toggleType {
    display: block; }

  &-Create {
    position: relative;
    .FilesUploadDroparea {
      position: static;
      &-overlay {
        border-radius: var(--FeedPostForm-border-radius); } }
    .RichMediaInput {
      .TextArea {
        textarea {
          &:focus, &:focus-within {
            box-shadow: none;
            outline: none; } } }
      .FileButton {
        padding: var(--vertical-padding) var(--horizontal-padding); } }

    .WYSIWYGInput {
      // margin: 2px
      border-left-width: 0;
      border-bottom-width: 0;
      border-right-width: 0;
      --ck-border-radius: 0;
      --ck-resizer-border-radius: 0;
      max-height: 60vh;
      overflow: auto; }

    .RichMediaInput .TextArea,
    .RichMediaInput .TextArea textarea,
    .WYSIWYGInput,
    .WYSIWYGInput .ck-rounded-corners .ck.ck-editor__top,
    .WYSIWYGInput .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
    .RichMediaInput .TextArea,
    .RichMediaInput .TextArea textarea {
      border-radius: 0; } } }
