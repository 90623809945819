$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
.ChatChannelAvatar {
  user-select: none;
  display: inline-block;
  border-radius: 100%;
  max-width: initial;
  background-color: var(--background-grey-darkened);
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;

  &-sm {
    height: 33px;
    width: 33px;
    font-size: 30px; }

  &-md {
    height: $AVATAR_HEIGHT / 2;
    width: $AVATAR_WIDTH / 2;
    font-size: 65px; }

  &-lg {
    height: $AVATAR_HEIGHT;
    width: $AVATAR_WIDTH;
    font-size: 130px; }

  &-bordered {
    box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.20); } }
