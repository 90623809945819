$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.FilesUploadDroparea {
  position: relative;

  &[data-disabled] {
    cursor: not-allowed;
    cursor: no-drop; }

  &[data-dropping]:not([data-disabled]) {
    cursor: grabbing; }

  &-overlay {
    opacity: 0;
    @include fillPositionedParent;
    z-index: 100;
    pointer-events: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.5);
    > .Icon {
      font-size: 150px;
      color: white;
      transform: scale(0.75);
      transition: transform 200ms ease-in-out; }
    > .Header {
      color: white;
      font-weight: bolder; } }

  &[data-dropping] &-overlay {
    opacity: 1;
    > .Icon {
      transform: scale(1); } }

  &[data-disabled] &-overlay {
    display: none; } }
