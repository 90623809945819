$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.Navbar {
  --height: 68px;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 var(--horizontal-space);
  min-height: var(--height);
  &-thin {
    --height: 50px; }

  > .Navbar {
    padding: 0 !important; }

  & > &-Space {
    @for $i from 1 through 8 {
      &-#{$i} {
        flex: 0 0 calc(var(--horizontal-space) * #{$i});
        width: calc(var(--horizontal-space) * #{$i}); } } }

  & > &-Grow {
    flex: 2 2 auto; }

  & > &-Break {
    flex-basis: 100%;
    width: 0; }

  > {
    .IconButton,
    .Button-normal,
    .Button-primary,
    .Button-success,
    .Button-transparent {
      margin: var(--vertical-space) 0;
      margin-left: var(--horizontal-space); } }

  > {
    .InterfaceHelp,
    .PageTab,
    .InterfaceHelp > .PageTab, {
      min-height: var(--height);
      margin: 0;
      align-self: stretch;
      display: flex;
      align-items: center;
      justify-content: space-around; } }


  &, > .InterfaceHelp {
    > {
      .PageTab {
        $horizontal-padding: calc(var(--horizontal-space) / 2);
        position: relative;
        padding: calc(var(--vertical-space) / 2) $horizontal-padding;
        &-selected {
          &:after {
            position: absolute;
            background-color: var(--tru-blue);
            bottom: 0;
            left: $horizontal-padding;
            right: $horizontal-padding;
            height: 3px;
            content: " "; } } }

      .IconButton,
      .Button-normal,
      .Button-primary,
      .Button-success,
      .Button-transparent {
        margin: auto calc(var(--horizontal-space) / 4); } }

    > .Header:first-child > .Icon {
      margin-right: var(--horizontal-space); } } }
