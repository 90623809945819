$RESPONSIVE_BREAKPOINT_PHONE: 500px;
$RESPONSIVE_BREAKPOINT_PHABLET: 768px;
$RESPONSIVE_BREAKPOINT_TABLET: 992px;
$RESPONSIVE_BREAKPOINT_DESKTOP: 1200px;
$AVATAR_HEIGHT: 150px;
$AVATAR_WIDTH: 150px;
$ICON_HEIGHT: 150px;
$ICON_WIDTH: 150px;
$BANNER_HEIGHT: 400px;
$BANNER_WIDTH: 1200px;
@import "style/mixins.sass";

.ContextPortal {
  position: relative;

  --shroud-background-color-from: rgba(0, 0, 0, 0);
  --shroud-background-color: rgba(0, 0, 0, 0.1);

  &-shroud {
    z-index: 2000;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    overscroll-behavior: none;
    background-color: var(--shroud-background-color);
    &, &:focus {
      outline: none; } }

  &-animation {
    &-enter {
      background-color: var(--shroud-background-color-from);
      > * {
        opacity: 0; } }
    &-enter-active {
      background-color: var(--shroud-background-color);
      transition: background-color 100ms ease-out;
      > * {
        opacity: 1;
        transition: opacity 200ms ease-out; } }
    &-exit {
      background-color: var(--shroud-background-color);
      pointer-events: none;
      > * {
        opacity: 1; } }
    &-exit-active {
      background-color: var(--shroud-background-color-from);
      transition: background-color 100ms ease-out;
      pointer-events: none;
      > * {
        opacity: 0;
        transition: opacity 200ms ease-out; } } } }
